import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-placed',
  templateUrl: './order-placed.component.html',
  styleUrls: ['./order-placed.component.scss'],
})
export class OrderPlacedComponent {
route = inject(ActivatedRoute);
status:any
constructor() {
this.status = this.route.snapshot.paramMap.get('status');
}
}
