import { Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SiteSetting } from 'src/app/shared/interfaces/sitesetting';
import { SettingService } from 'src/app/shared/services/setting.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/admin/dashboard', title: 'Dashboard', icon: 'pi pi-home', class: '' },
  { path: '/admin/orders', title: 'Orders', icon: 'pi pi-shopping-cart', class: '' },
  { path: '/admin/catagories', title: 'Catagories', icon: 'pi pi-list', class: '' },
  { path: '/admin/banners', title: 'Banners', icon: 'pi pi-image', class: '' },
  { path: '/admin/users', title: 'Users', icon: 'pi pi-user', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menuItems: any[];
  siteOffline:FormControl = new FormControl();
  authService = inject(AuthService);
  settingService = inject(SettingService);
  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.siteOffline.setValue(this.settingService.siteSetting.offline)
  }
  isMobileMenu() {
  };

  onSiteActiveChange(){
    debugger
  const siteSetting:SiteSetting = {...this.settingService.siteSetting,offline:this.siteOffline.value};
    this.settingService.setSetting(siteSetting).subscribe((r:any)=>{
      console.log(r);
      
    })
  }
}
