<div class="container">
    <div class="row full">
        <div class="col-lg-12 text-center my-auto" [ngClass]="status == '100'?'text-success':'text-danger'">
            <span><i class="pi pi-check fw-bold fs-2 mb-2"></i></span>
            <p class="fw-bold fs-4 mb-2">
                {{status =='100' ?
                'Order placed Successfully':'Order Not Placed'
                }}
            </p>
            <p *ngIf="status !=='100'" class="text-secondary">
                Please contact at
                <a href="tel:+919419444700">+91 9419444700</a>
                or
                <a href="mailto:feedback@tiffinaaw.com">feedback@tiffinaaw.com</a>
                for inqueries/order
            </p>

            <button routerLink="/public/home" pButton class="p-button-success p-button-rounded p-2 m-1 m-5"
                icon="pi pi-home" label="Home"></button>
        </div>
    </div>
</div>