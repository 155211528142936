<div class="container-xxl text-dark bg-light">
  <div class="row pt-2">
    <div class="col-sm-12 col-md-4 serving-homemade">
      <img src="../../../assets/img/logo_tiffin_aaw.webp" style="height: 90px;">
    </div>
    <div class="col-sm-12 col-md-4 my-2 address">
      <p>
        <i class="pi pi-map-marker me-2"></i>Rajbagh near IGNOU Centre, Srinagar,
        190008 J&K
      </p>
      <p>
        <i class="pi pi-phone me-2"></i> <a href="tel:+919419444700">+919419444700</a>
      </p>
      <p>
        <i class="pi pi-envelope me-2"></i> <a href="mailto:feedback@tiffinaaw.com">
          feedback@tiffinaaw.com</a>
      </p>
    </div>
    <div class="col-sm-12 col-md-4 my-1 text-center">
      <a href="https://wa.me/+919419444700" target="_blank" class="text-success"> <i
          class="pi pi-whatsapp fs-1 p-1"></i>
      </a>
      <a href="https://www.facebook.com/tiffinaaw" target="_blank" class="text-primary"> <i
          class="pi pi-facebook fs-1 p-1"></i>
      </a>
      <a href="https://instagram.com/tiffinaaw?igshid=YmMyMTA2M2Y=" target="_blank" class="text-danger"> <i
          class="pi pi-instagram fs-1 p-1"></i>
      </a>
    </div>
    <div class="col-lg-12 text-center">
      <span class="links green-vl" routerLink="/public/privacy-policy">
        Privacy Policy
      </span>
      <span class="links green-vl" routerLink="/public/terms-and-conditions">
        Terms and conditions
      </span>
      <span class="links green-vl" routerLink="/public/cancellation-and-refund">
        Cancellation and refund
      </span>
      <span class="links green-vl" routerLink="/public/shipping-delivery">
        Shipping and delivery
      </span>
      <span class="links" routerLink="/public/contact-us">
        Contact us
      </span>
    </div>
    <div class="row justify-content-md-center text-center p-0">
      <app-ufklogo />
    </div>
  </div>
</div>