import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { io } from 'socket.io-client';
import { FetchOrderModel } from 'src/app/shared/interfaces/fetch-completed-orders';
@Injectable()
export class AdminOrdersService {

  private socket: any;
  latestOrder: Subject<any> = new Subject();
  constructor(private http: HttpClient) {
  }

  connect() {
    this.socket = io(`${environment.socketURL}`, { transports: ['websocket'], secure: true });
    this.socket.on('connect', () => { console.log(); });
    this.socket.on('connect_error', (err) => {
      console.log('socket error ::', err);
    });
  }

  GetCompletedOrders(FetchCompOrderModel: FetchOrderModel) {
    return this.http.post(`${environment.server}Orders/GetCompletedOrders`, FetchCompOrderModel);
  }

  GetLatestOrders() {
    return this.http.get(`${environment.server}Orders/GetLatestOrders`);
  }

  GetFutureOrders() {
    return this.http.get(`${environment.server}Orders/GetFutureOrders`);
  }

  SetCompletedOrder(order: any) {
    return this.http.post(`${environment.server}Orders/SetCompletedOrder/${order?._id}`, order);
  }

  FetchNewCreatedOrder(): Observable<any> {
    return new Observable((observer) => {
      this.socket?.on('newOrder', (order: any) => {
        if(order){
          observer.next(order);
        }
      });
    });
  }
  GetAdminCreatedOrders(payload: any) {
    return this.http.post(`${environment.server}Orders/GetAdminOrders`, payload)
  }
  GetAdminCreatedOrder(_id: string) {
    return this.http.get(`${environment.server}Orders/GetAdminOrders/${_id}`);
  }
  FetchAdminLatestOrders(payload: any) {
    return this.http.post(`${environment.server}Orders/GetAdminLatestOrders`, payload)
  }
  FetchAdminFutureOrders(payload: any) {
    return this.http.post(`${environment.server}Orders/GetAdminFutureOrders`, payload)
  }
  UpdateAdminCreatedOrder(payload: any) {
    return this.http.put(`${environment.server}Orders/UpdateAdminCreatedOrder/${payload?._id}`, payload)
  }
  GetAdminCompletedOrders(payload: any) {
    return this.http.post(`${environment.server}Orders/GetAdminCompletedOrders`, payload)
  }

  CancelOrder(id: string, deletedReason: string, type: string) {
    return this.http.put(`${environment.server}Orders/cancelOrders/${id}`,{deletedReason, type});
  }

  DeleteOrder(id: string, type: string) {
    return this.http.delete(`${environment.server}Orders/deleteOrders/${id}`, {
      params: {
        type
      }
    });
  }

  disconnect() {
    this.socket.on('disconnect', () => {
    });
  }

  FetchAdminCancelledOrders(payload: any){
    return this.http.get(`${environment.server}Orders/GetAdminsCancelledOrders`, payload);
  }

  FetchUsersCancelledOrders(payload: any){
    return this.http.get(`${environment.server}Orders/GetUsersCancelledOrders`, payload);
  }
}
