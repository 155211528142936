<div class="container-fluid full d-flex align-items-center justify-content-center" style="background: #f0f0f0e2;">
    <div>
        <h1>
            <strong class="text-success">We are offline for now !</strong>
        </h1>
    </div>
    <img src="/assets/img/offline.jpg" height="auto" width="100%" class="off-image">
    <p class="text text-secondary w-50 fw-bold">
        We regret to inform you, we are offline for maintenance. We will be back soon!
    </p>
</div>