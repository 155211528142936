<div class="container-xxl mb-2 px-0" style="box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);">
  <nav class="navbar navbar-expand-lg custom-nav p-0" #nav>
    <div class="container-fluid p-3  dialog-nav" >
      <a class="navbar-brand px-1" routerLink="/public/home">
        <img src="./../../../assets/img/tiifen-word-only.png" style="height: 30px;" alt="Tiffin Aww">
      </a>
      <a class="navbar-brand px-2" *ngIf="!url?.includes('checkout') && !url?.includes('my-orders')">
        <p-dropdown [options]="regions" optionLabel="label" [filter]="true" filterBy="label" tooltipPosition="bottom"
          dropdownIcon="pi pi-globe" [formControl]="selectedRegion" placeholder="Select region">
        </p-dropdown>
      </a>
      <p-dropdown appendTo="body" placeholder="''" [formControl]="selected" class="navbar-toggler"
        (onChange)="NavigateTo($event?.value)" [options]="Items" optionLabel="label" dropdownIcon="pi pi-user"
        [filter]="false">
        <ng-template let-item pTemplate="item">
          <div class="item">
            <span> <i [class]="item?.icon + ' ' + 'mr-2'"></i></span>
            <span>{{item.label}}</span>
          </div>
        </ng-template>
      </p-dropdown>
      <div class="collapse navbar-collapse justify-content-end m-0" id="navbarNav"
        [ngClass]="{'collapse': collapsed, 'navbar-collapse': true}">
        <ul class="navbar-nav">
          <li *ngFor="let item of Items" class="nav-item ms-4">
            <a class="nav-link show-cursor" (click)="NavigateTo(item)" aria-current="page"><i
                [class]="item?.icon + ' '+ 'mr-2' "></i>{{item?.label}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="text-center">
      <p-dialog header="Confirm region" [(visible)]="showSelected" [modal]="true" [style]="{width: '95vmin'}"
        [draggable]="false" (onHide)="onDialogHide()">
        <p class="text-font">We are time based for our order timing!</p>
        <p class="text-center text-font fs-2">{{selectedRegion?.value?.label}}</p>
        <p class="text-font ">Lunch : {{selectedRegion?.value?.lunch}}</p>
        <p class="text-font ">Dinner : {{selectedRegion?.value?.dinner}}</p>
        <ng-template pTemplate="footer">
          <div class="col text-center proceed">
            <!-- <button (click)="AcceptRegion()" pButton pRipple type="button" label="Proceed"
              class="p-button-success ta-proceed p-button-rounded"></button> -->
              <button type="button" class="proceed-btn text-white" (click)="AcceptRegion()">Proceed</button>
          </div>
        </ng-template>
      </p-dialog>
    </div>
  </nav>
</div>