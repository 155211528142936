import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IloggedUser } from 'src/app/shared/interfaces/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authService = inject(AuthService);
    const loggedUser:IloggedUser = authService.LoggedInUser.getValue() ?? JSON.parse(localStorage.getItem('user') || '{}');
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${loggedUser ? loggedUser?.token : ''}`,
      },
    });
    return next.handle(request);
  }
}
