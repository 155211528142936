import { Injectable, inject } from '@angular/core';
import { SiteSetting } from '../interfaces/sitesetting';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, of } from 'rxjs';
import { Route, Router, Routes } from '@angular/router';
import { OfflineComponent } from 'src/app/offline/offline.component';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  siteSetting!: SiteSetting;
  http = inject(HttpClient);
  router = inject(Router);
  wildcard_route:Route = {path:'**',component:OfflineComponent}
  constructor() { }

  loadSetting() {
    return this.http.get(`${environment.server}Site`).pipe(map((res: any) => {
      this.siteSetting = res?.data;
      if (this.siteSetting && this.siteSetting.offline) {
        const online_routes = ['', '**', 'public', 'order-placed/:status'];
        const offline_configured_routes = this.router.config.filter(r => online_routes.find(or => or === r.path) ? false : true);
        const startup = offline_configured_routes.find(r => r.path === '');
        if (startup) {
          startup.component = OfflineComponent;
        }
        if(!this.hasWildCardRoute(offline_configured_routes)){
          offline_configured_routes.push(this.wildcard_route)
        }
        this.router.resetConfig(offline_configured_routes);
        console.log(offline_configured_routes);
        return true;
      }
      return true;
    }),
      catchError((error, caught) => {
        return of(true);
      }));
  }


  setSetting(setting: SiteSetting) {
    return this.http.post(`${environment.server}Site`, setting);
  }

  hasWildCardRoute(routes:Route[]){
    return routes.find(r=> r.path.includes('*')) ? true:false;
  }

}
